import React, { useContext } from 'react'
import styles from './AuthClients.module.scss'
import Table from '../../components/Table/Table'
import { AuthClientsContext } from '../../providers/AuthClientContext'
import { AuthClient } from '../../types/authClient'
import { TableRows, TableContent, TableEditCellState, TableHeaders } from '../../types/table'
import { updateAuthClient as apiUpdateAuthClient } from '../../api/api'

type AuthClientsTableContent = keyof Omit<AuthClient, 'id' | 'pk' | 'PK' | 'sk' | 'SK' | 'clientId'>

const AuthClients = () => {
  const { authClients, updateAuthClient } = useContext(AuthClientsContext)

  const handleSave = (newValue: TableEditCellState<AuthClientsTableContent>) => {
    const newAuthClient: AuthClient = {
      ...authClients[newValue.rowIndex],
      [newValue.cellKey]: newValue.value
    }

    apiUpdateAuthClient(newAuthClient)
      .then(() => { updateAuthClient(newAuthClient) })
      .catch((e) => console.error(e)) // @todo show toast
  }

  const mapAuthClientsToTableRows = (
    clients: AuthClient[]
  ): TableRows<AuthClientsTableContent> =>
    clients.map(client => ({
      title: client.title || 'N/A',
      titleId: client.titleId || 'N/A',
      clientName: client.clientName || 'N/A',
      clientSecret: client.clientSecret,
      redirectUris: client.redirectUris,
      postLogoutRedirectUris: client.postLogoutRedirectUris,
    }))

  const headers: TableHeaders<AuthClientsTableContent> = [
    { key: 'title', label: 'Game Title' },
    { key: 'titleId', label: 'Title ID' },
    { key: 'clientName', label: 'Client Name' },
    { key: 'clientSecret', label: 'Client Secret' },
    { key: 'redirectUris', label: 'Redirect URL' },
    { key: 'postLogoutRedirectUris', label: 'Post Logout Redirect URL' }
  ]

  const tableContent: TableContent<AuthClientsTableContent> = {
    headers,
    rows: mapAuthClientsToTableRows(authClients),
  }

  return (
    <div className={styles.authClients}>
      <h1>AuthClients List</h1>
      <Table
        tableContent={tableContent}
        handleSave={handleSave}
      />
    </div>
  )
}

export default AuthClients