import { http, HttpResponse } from 'msw'
import { getMockAuthClient, getTitleMock, getCountryMock } from './mocks'
import { setupWorker } from 'msw/browser'

const API_BASE_URL = process.env.REACT_APP_API_URL

export const handlers = [
  http.get(`${API_BASE_URL}/api/v1/start`, () => {
    return HttpResponse.json({ csrfToken: 'abc' })
  }),
  http.get(`${API_BASE_URL}/v1/admin/titles`, () => {
    return HttpResponse.json([getTitleMock(), getTitleMock()])
  }),
  http.get(`${API_BASE_URL}/v1/admin/titles/:titleId`, () => {
    return HttpResponse.json(getTitleMock())
  }),
  http.put(`${API_BASE_URL}/v1/admin/titles/:titleId`, async ({ request }) => {
    return HttpResponse.json(await request.json()) // @todo https://www.edstem.com/blog/mock-service-worker-development-testing/
  }),
  http.post(`${API_BASE_URL}/v1/admin/titles/:imageCategory/:titleId`, () => {
    return HttpResponse.json()
  }),
  http.delete(`${API_BASE_URL}/v1/admin/titles/:imageCategory/:titleId`, () => {
    return HttpResponse.json()
  }),
  http.get(`${API_BASE_URL}/v1/admin/authclients`, () => {
    return HttpResponse.json([getMockAuthClient(), getMockAuthClient()])
  }),
  http.get(`${API_BASE_URL}/v1/admin/authclients/:titleId`, () => {
    return HttpResponse.json([getMockAuthClient()])
  }),
  http.post(`${API_BASE_URL}/v1/admin/authclients`, () => {
    return HttpResponse.json(getMockAuthClient())
  }),
  http.put(`${API_BASE_URL}/v1/admin/authclients/:pk`, async ({ request }) => {
    // await delay(3000)
    return HttpResponse.json(await request.json())
  }),
  http.delete(`${API_BASE_URL}/v1/admin/authclients/:pk/:sk`, () => {
    return HttpResponse.json()
  }),
  http.get(`${API_BASE_URL}/v1/admin/countries`, () => {
    return HttpResponse.json([getCountryMock(), getCountryMock()])
  })
]

export const worker = setupWorker(...handlers)
