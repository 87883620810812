import React, { useEffect, useState } from 'react'
import { Modal, Button, Spinner } from 'react-bootstrap'
import FileUploadField from '../FileUploadField/FileUploadField'
import { deleteImage, getTitleDetailsByTitleId, uploadImage } from '../../api/api'
import { imageKeyToImageCategory } from '../../utils/camelToSnake'
import { ImageKey } from '../../types/imageCategory'

type UploadImagesModalProps = {
  show: boolean
  handleClose: () => void
  title: string
  titleId: string
  modalTitle: string
  uploadFieldName: string
  uploadFieldDescription: string
  imagesKey: ImageKey
}

const UploadImagesModal = ({
  show,
  handleClose,
  title,
  titleId,
  modalTitle,
  uploadFieldName,
  uploadFieldDescription,
  imagesKey
}: UploadImagesModalProps) => {
  const [uploading, setUploading] = useState(false)
  const [files, setFiles] = useState<{[key: string]: File[]}>({})
  const [existingImages, setExistingImages] = useState([])
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  const [deleting, setDeleting] = useState(false)

  useEffect(() => {
    show && getTitleDetailsByTitleId(titleId)
      .then((titleDetails) => {
        const uniqueImages = [...new Set(titleDetails[imagesKey] || [])]
        setExistingImages(uniqueImages)
      })
      .catch((e) => console.log('Image fetch error', e))
      .finally(() => setLoading(false))
  }, [show, titleId, imagesKey])

  const handleFileChange = (e, field) => {
    const newFiles = Array.from(e.target.files)
    setFiles((prev) => ({
      ...prev,
      [field]: [...(prev[field] || []), ...newFiles],
    }))
  }

  const handleRemoveFile = async (field: string, index: number, isExisting = false) => {
    if (isExisting) {
      const imageUrl = existingImages[index]
      if (deleting) return
      setDeleting(true)

      deleteImage(titleId, imageKeyToImageCategory(imagesKey), encodeURIComponent(imageUrl))
        .then(() => {
          setExistingImages((prev) => prev.filter((_, i) => i !== index))
          setSuccessMessage(`Image deleted successfully!`)
        })
        .catch((e) => setErrorMessage(e))
        .finally(() => setDeleting(false))
    } else {
      setFiles((prev) => ({
        ...prev,
        [field]: prev[field].filter((_, i) => i !== index),
      }))
    }
  }

  const handleImageUpload = async () => {
    const uploadTasks = []
    setUploading(true)

    Object.values(files).forEach((fileList: File[]) => {
      fileList.forEach(file => {
        uploadTasks.push(
          uploadImage(file, imageKeyToImageCategory(imagesKey), titleId)
            .then(() => setSuccessMessage(`Image uploaded successfully!`))
            .catch(setErrorMessage)
        )
      })
    })

    await Promise.all(uploadTasks)
      .catch(() => alert('One or more images failed to upload. Please check the console for details.'))
      .finally(() => setUploading(false))
  }

  const isSaveDisabled = uploading || (
    Object.values(files)
      .every((fileList: File[]) => fileList.length === 0)
      && existingImages.length === 0
  )

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{modalTitle.replace('{title}', title)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {successMessage && (
          <div className="alert alert-info" role="alert">
            {successMessage}
          </div>
        )}
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}
        {loading ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <FileUploadField
            fieldName="images"
            label={uploadFieldName || 'Upload here!'}
            description={uploadFieldDescription || 'SVG, PNG, or JPG'}
            files={files.images || []}
            onFileChange={handleFileChange}
            onRemoveFile={(field, index) => handleRemoveFile(field, index)}
            existingImages={existingImages}
            onRemoveExistingImage={(index) => handleRemoveFile(null, index, true)}
            deleting={deleting}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={uploading}>
          Close
        </Button>
        <Button variant="primary" onClick={handleImageUpload} disabled={isSaveDisabled}>
          {uploading ? 'Saving...' : 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UploadImagesModal
