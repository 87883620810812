import React from 'react'
import { Pagination } from 'react-bootstrap'

type RenderPaginationProps = {
  currentPage: number
  totalPages: number
  paginate: (number: number) => void
}

export const renderPagination = ({ currentPage, totalPages, paginate }: RenderPaginationProps) => {
  const windowSize = 3
  const startPage = Math.max(1, Math.min(currentPage - 1, totalPages - windowSize + 1))
  const endPage = Math.min(totalPages, startPage + windowSize - 1)

  const items = []

  if (startPage > 1) {
    items.push(
      <Pagination.First key="first" onClick={() => paginate(1)} />,
      <Pagination.Prev key="prev" onClick={() => paginate(currentPage - 1)} />
    )
  }

  for (let number = startPage; number <= endPage; number++) {
    items.push(
      <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
        {number}
      </Pagination.Item>,
    )
  }

  if (endPage < totalPages) {
    items.push(
      <Pagination.Next key="next" onClick={() => paginate(currentPage + 1)} />,
      <Pagination.Last key="last" onClick={() => paginate(totalPages)} />
    )
  }

  return (
    <Pagination className="justify-content-center">
      {items}
    </Pagination>
  )
}
