import React, { useState, useContext } from 'react'
import { Alert, Spinner, Toast } from 'react-bootstrap'
import SelectCountriesModal from '../../components/SelectCountriesModal/SelectCountriesModal'
import ConfigureAuthClientModal from '../../components/ConfigureAuthClientModal/ConfigureAuthClientModal'
import GameOptionsModal from '../../components/GameOptionsModal/GameOptionsModal'
import TitlesList from '../../components/TitlesList/TitlesList'
import ViewAuthClientsModal from '../../components/ViewAuthClientsModal/ViewAuthClientsModal'
import { TitleDetailsContext } from '../../providers/TitleDetailsContext'
import styles from './Titles.module.scss'

const Titles = () => {
  const { titleDetails, loading, error } = useContext(TitleDetailsContext)
  const [showCountriesModal, setShowCountriesModal] = useState(false)
  const [showAuthClientModal, setShowAuthClientModal] = useState(false)
  const [showViewAuthClientsModal, setShowViewAuthClientsModal] = useState(false)
  const [showGameOptionsModal, setShowGameOptionsModal] = useState(false)
  const [selectedTitle, setSelectedTitle] = useState(null)
  const [showAuthClientToast, setShowAuthClientToast] = useState(false)
  const [toastMessageForAuthClient, setToastMessageForAuthClient] = useState('')

  const handleCloseModal = () => {
    setShowCountriesModal(false)
    setShowAuthClientModal(false)
    setShowViewAuthClientsModal(false)
    setShowGameOptionsModal(false)
    setSelectedTitle(null)
  }

  const handleShowCountriesModal = (title) => {
    setSelectedTitle(title)
    setShowCountriesModal(true)
  }

  const handleShowAuthClientModal = (title) => {
    setSelectedTitle(title)
    setShowAuthClientModal(true)
  }

  const handleShowViewAuthClientModal = (title) => {
    setSelectedTitle(title)
    setShowViewAuthClientsModal(true)
  }

  const handleShowGameOptionsModal = (title) => {
    setSelectedTitle(title)
    setShowGameOptionsModal(true)
  }

  const handleSuccessSaveAuthClientSettings = (message) => {
    setToastMessageForAuthClient(message)
    setShowAuthClientToast(true)
    setTimeout(() => {
      setShowAuthClientToast(false)
    }, 3000) // Clearing the message after 3 seconds
  }

  const renderSpinner = () => (
    <div className="d-flex justify-content-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  )

  return (
    <div className={styles.titles}>
      <h1>Title List</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      {loading
        ? renderSpinner()
        : <TitlesList
          titles={titleDetails}
          handleShowCountriesModal={handleShowCountriesModal}
          handleShowAuthClientModal={handleShowAuthClientModal}
          handleShowViewAuthClientModal={handleShowViewAuthClientModal}
          handleShowGameOptionsModal={handleShowGameOptionsModal}
        />
      }

      {selectedTitle && (
        <>
          <SelectCountriesModal
            show={showCountriesModal}
            handleClose={handleCloseModal}
            title={selectedTitle.title}
            titleId={selectedTitle.titleId}
          />
          <ConfigureAuthClientModal
            show={showAuthClientModal}
            handleClose={handleCloseModal}
            title={selectedTitle.title}
            titleId={selectedTitle.titleId}
            onSuccessSave={handleSuccessSaveAuthClientSettings}
          />
          <ViewAuthClientsModal
            show={showViewAuthClientsModal}
            handleClose={handleCloseModal}
            title={selectedTitle.title}
            titleId={selectedTitle.titleId} />
          <GameOptionsModal
            show={showGameOptionsModal}
            handleClose={handleCloseModal}
            title={selectedTitle.title}
            titleId={selectedTitle.titleId}
          />
        </>
      )}

      <Toast onClose={() => setShowAuthClientToast(false)} show={showAuthClientToast} delay={3000} autohide bg='dark' style={{ position: 'fixed', top: '20px', right: '20px', color: 'white' }}>
        <Toast.Body>{toastMessageForAuthClient}</Toast.Body>
      </Toast>
    </div>
  )
}

export default Titles