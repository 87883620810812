import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import UploadImagesModal from '../UploadGameCardModal/UploadImagesModal'
import GameDetailsForm from '../GameDetailsForm/GameDetailsForm'

type GameOptionsModalProps = {
  show: boolean
  handleClose: () => void
  title: string
  titleId: string
}

const GameOptionsModal = ({
  show, handleClose, title, titleId
}: GameOptionsModalProps) => {
  const [activeModal, setActiveModal] = useState(null)

  const handleCloseModal = () => {
    setActiveModal(null) // Close any active modal
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Add settings for {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="link" onClick={() => setActiveModal('GameUrl')}>Update Game Info</Button>
          <Button variant="link" onClick={() => setActiveModal('RecommendedGame')}>Upload Images for Recommended Games</Button>
          <Button variant="link" onClick={() => setActiveModal('GameCard')}>Upload Images for Games Card</Button>
          <Button variant="link" onClick={() => setActiveModal('FeaturedGame')}>Upload Images for Featured Games</Button>
          <Button variant="link" onClick={() => setActiveModal('Banner')}>Upload Images for Banner</Button>
        </Modal.Body>
      </Modal>

      {activeModal === 'GameUrl' && (
        <GameDetailsForm
          show={true}
          handleClose={handleCloseModal}
          title={title}
          titleId={titleId}
        />
      )}

      {activeModal === 'RecommendedGame' && (
        <UploadImagesModal
          show={true}
          handleClose={handleCloseModal}
          title={title}
          titleId={titleId}
          modalTitle="Upload Recommended Game images for {title}"
          uploadFieldName="Upload Recommended Game Images"
          uploadFieldDescription="SVG, PNG, or JPG (rec. 700x430px)"
          imagesKey="recommendedGameImages"
        />
      )}

      {activeModal === 'GameCard' && (
        <UploadImagesModal
          show={true}
          handleClose={handleCloseModal}
          title={title}
          titleId={titleId}
          modalTitle="Upload Game images for {title}"
          uploadFieldName="Upload Game Card Images"
          uploadFieldDescription="SVG, PNG, or JPG (rec. 140x140px)"
          imagesKey="gamecardImages"
        />
      )}

      {activeModal === 'FeaturedGame' && (
        <UploadImagesModal
          show={true}
          handleClose={handleCloseModal}
          title={title}
          titleId={titleId}
          modalTitle="Upload Featured Game images for {title}"
          uploadFieldName="Upload Featured Game Images"
          uploadFieldDescription="SVG, PNG, or JPG (rec. 922x390px)"
          imagesKey="featuredGameImages"
        />
      )}

      {activeModal === 'Banner' && (
        <UploadImagesModal
          show={true}
          handleClose={handleCloseModal}
          title={title}
          titleId={titleId}
          modalTitle="Upload Banner images for {title}"
          uploadFieldName="Upload Banner Images"
          uploadFieldDescription="SVG, PNG, or JPG (rec. 572x184px)"
          imagesKey="bannerImages"
        />
      )}
    </>

  )
}

export default GameOptionsModal
