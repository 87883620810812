import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Alert } from 'react-bootstrap'
import './ConfigureAuthClientModal.scss'
import { createAuthClient } from '../../api/api'
import { AuthClient } from '../../types/authClient'

export type ConfigureAuthClientModalProps = {
  show: boolean
  handleClose: () => void
  title: string
  titleId: string
  onSuccessSave: (message: string) => void
}

const ConfigureAuthClientModal = ({
  show, handleClose, title, titleId, onSuccessSave
}: ConfigureAuthClientModalProps) => {
  // @todo replace with activeAuthClient
  const [clientSecret, setClientSecret] = useState('')
  const [clientName, setClientName] = useState('')
  const [redirectUris, setRedirectUris] = useState('')
  const [postLogoutRedirectUris, setPostLogoutRedirectUris] = useState('')
  const [clientSettings, setClientSettings] = useState('')
  const [tokenSettings, setTokenSettings] = useState('')
  const [saving, setSaving] = useState(false)
  const [saveError, setSaveError] = useState(null)

  useEffect(() => {
    if (show) {
      // Resetting form fields when modal is shown
      setClientSecret('')
      setClientName('')
      setRedirectUris('')
      setPostLogoutRedirectUris('')
      setClientSettings('')
      setTokenSettings('')
      setSaveError(null)
    }
  }, [show])

  const handleSave = async () => {
    setSaving(true)
    setSaveError(null)

    const authClientData: Partial<AuthClient> = {
      titleId: titleId,
      clientSecret: clientSecret,
      clientName: clientName,
      redirectUris: redirectUris,
      postLogoutRedirectUris: postLogoutRedirectUris,
    }

    await createAuthClient(authClientData)
      .then(() => {
        handleClose()
        onSuccessSave('Auth Client Settings successfully saved.')
      })
      .catch(setSaveError)
      .finally(() => setSaving(false))
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Configure AuthClient for {title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className='auth-form'>
          <Form.Group controlId="titleId">
            <Form.Label>Title ID</Form.Label>
            <Form.Control
              type="text"
              value={titleId}
              placeholder="Enter title ID"
              disabled
              required
            />
          </Form.Group>
          <Form.Group controlId="clientSecret">
            <Form.Label>Client Secret</Form.Label>
            <Form.Control
              type="text"
              value={clientSecret}
              onChange={(e) => setClientSecret(e.target.value)}
              placeholder="Enter client secret"
              required
            />
          </Form.Group>
          <Form.Group controlId="clientName">
            <Form.Label>Client Name</Form.Label>
            <Form.Control
              type="text"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
              placeholder="Enter client name"
              required
            />
          </Form.Group>
          <Form.Group controlId="redirectUris">
            <Form.Label>Redirect URIs</Form.Label>
            <Form.Control
              type="text"
              value={redirectUris}
              onChange={(e) => setRedirectUris(e.target.value)}
              placeholder="Enter redirect URIs, comma separated"
              required
            />
          </Form.Group>
          <Form.Group controlId="postLogoutRedirectUris">
            <Form.Label>Post Logout Redirect URIs</Form.Label>
            <Form.Control
              type="text"
              value={postLogoutRedirectUris}
              onChange={(e) => setPostLogoutRedirectUris(e.target.value)}
              placeholder="Enter post logout redirect URIs, comma separated"
              required
            />
          </Form.Group>
          <Form.Group controlId="clientSettings">
            <Form.Label>Client Settings</Form.Label>
            <Form.Control
              type="text"
              value={clientSettings}
              onChange={(e) => setClientSettings(e.target.value)}
              placeholder="Enter client settings as JSON string"
              required
            />
          </Form.Group>
          <Form.Group controlId="tokenSettings">
            <Form.Label>Token Settings</Form.Label>
            <Form.Control
              type="text"
              value={tokenSettings}
              onChange={(e) => setTokenSettings(e.target.value)}
              placeholder="Enter token settings as JSON string"
              required
            />
          </Form.Group>
        </Form>
        {saveError && <Alert variant="danger" className="mt-3">{saveError}</Alert>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
                    Close
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={saving}>
          {saving ? 'Saving...' : 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfigureAuthClientModal
