import React, { useState, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { getTitleDetailsByTitleId, updateTitleDetails } from '../../api/api'
import { TitleDetails } from '../../types/titleDetails'
import { Tag } from '../../types/tag'
import { Platform } from '../../types/platform'

type GameDetailsFormProps = {
  show: boolean
  handleClose: () => void
  title: string
  titleId: string
}

const GameDetailsForm = ({
  show, handleClose, title, titleId
}: GameDetailsFormProps) => {
  const [gameUrl, setGameUrl] = useState('')
  const [platforms, setPlatforms] = useState<Platform[]>([])
  const [tags, setTags] = useState<Tag[]>([])
  const [fullTitleDetails, setFullTitleDetails] = useState<TitleDetails | null>(null)
  const [loading, setLoading] = useState(true)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const availablePlatforms: Platform[] = ['MOBILE', 'STEAM', 'WINDOWS']
  const availableTags: Tag[] = ['RECOMMENDED', 'FEATURED']

  useEffect(() => {
    show && getTitleDetailsByTitleId(titleId)
      .then((titleDetails) => {
        setFullTitleDetails(titleDetails)
        setGameUrl(titleDetails.gameLaunchUrl)
        setPlatforms(titleDetails.platforms)
        setTags(titleDetails.tags ?? [])
      })
      .catch(() => setErrorMessage('Failed to fetch game details.'))
      .finally(() => setLoading(false))
  }, [show, titleId])

  const handleSaveGameUrl = async () => {
    setLoading(true)

    const updatedTitle: TitleDetails = {
      ...fullTitleDetails,
      gameLaunchUrl: gameUrl,
      platforms,
      tags
    }

    updateTitleDetails(titleId, updatedTitle)
      .then(() => {
        setSuccessMessage('Game URL updated successfully!')
        setTimeout(() => {
          setSuccessMessage(null)
          handleClose()
        }, 2000)
      })
      .catch(() => setErrorMessage('Failed to update the game URL. Please try again.'))
      .finally(() => setLoading(false))
  }

  const toggleCheckbox = (value, setter, currentValues) => {
    if (currentValues?.includes(value)) {
      setter(currentValues.filter((v) => v !== value))
    } else {
      setter([...currentValues, value])
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Update Game Details for {title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {successMessage && (
          <div className="alert alert-info" role="alert">
            {successMessage}
          </div>
        )}
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}
        {loading ? (
          <div>Loading...</div>
        ) : (
          <Form>
            <Form.Group controlId="gameUrl">
              <Form.Label>Game Launch URL:</Form.Label>
              <Form.Control
                type="text"
                value={gameUrl}
                onChange={(e) => setGameUrl(e.target.value)} // Update state on input change
                placeholder="Enter Game URL"
                required
              />
            </Form.Group>

            <Form.Group controlId="platforms" className="mb-3">
              <Form.Label>Platforms:</Form.Label>
              {availablePlatforms.map((platform) => (
                <Form.Check
                  key={platform}
                  type="checkbox"
                  label={platform}
                  checked={platforms?.includes(platform)}
                  onChange={() => toggleCheckbox(platform, setPlatforms, platforms)}
                />
              ))}
            </Form.Group>

            <Form.Group controlId="tags" className="mb-3">
              <Form.Label>Tags:</Form.Label>
              {availableTags.map((tag) => (
                <Form.Check
                  key={tag}
                  type="checkbox"
                  label={tag}
                  checked={tags?.includes(tag)}
                  onChange={() => toggleCheckbox(tag, setTags, tags)}
                />
              ))}
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={loading}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSaveGameUrl} disabled={loading}>
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default GameDetailsForm
