import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Navbar.module.scss'
import { Button } from 'react-bootstrap'
import StillfrontLogo from '../../assets/Stillfront-logo.svg'

const Navbar = () => {
  return <div className={styles.navbar}>
    <div className={styles.logoAndNavigation}>
      <img src={StillfrontLogo} />
      <div className={styles.links}>
        <Link to="/titles">Titles</Link>
        <Link to="/authClients">AuthClients</Link>
      </div>
    </div>
    <Button className={styles.logoutButton}>Logout</Button>
  </div>
}

export default Navbar