import { TitleDetailsProvider } from './TitleDetailsContext'
import React, { ReactNode } from 'react'
import { AuthClientsProvider } from './AuthClientContext'

const Providers = ({ children }: { children: ReactNode }) =>
  <AuthClientsProvider>
    <TitleDetailsProvider>
      {children}
    </TitleDetailsProvider>
  </AuthClientsProvider>

export default Providers