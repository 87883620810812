import React, { useState } from 'react'
import { Table as BootstrapTable } from 'react-bootstrap'
import styles from './Table.module.scss'
import { TableEditCellState, TableProps } from '../../types/table'

const Table = <TableContentType extends string>({
  tableContent,
  handleSave,
}: TableProps<TableContentType>) => {
  const [editCell, setEditCell] = useState<TableEditCellState<TableContentType> | null>(null)

  const handleEdit = (rowIndex: number, cellKey: TableContentType) => {
    const currentValue = tableContent.rows[rowIndex][cellKey]
    setEditCell({ rowIndex, cellKey, value: currentValue })
  }

  const save = () => {
    // @todo handle loading logic
    const isNewValue = editCell.value !== tableContent.rows[editCell.rowIndex][editCell.cellKey]

    isNewValue &&
      handleSave({
        rowIndex: editCell.rowIndex,
        cellKey: editCell.cellKey,
        value: editCell.value 
      })

    setEditCell(null)
  }

  const handleChange = (value: string) =>
    editCell && setEditCell({ ...editCell, value })

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) =>
    e.key === 'Enter' && save()

  return (
    <div className={styles.tableContainer}>
      {/* @todo add filter input */}
      {/* @todo add pagination */}
      <BootstrapTable striped hover className={styles.table}>
        <thead>
          <tr>
            {tableContent.headers.map(header => (
              <th key={header.key} className={styles.tableHeader}>{header.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableContent.rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Object.keys(row).map((cellKey, headerIndex) => (
                <td key={headerIndex} onClick={() => handleEdit(rowIndex, cellKey as TableContentType)} className={styles.tableCell}>
                  {editCell?.rowIndex === rowIndex && editCell.cellKey === cellKey
                    ? (
                      <input
                        type="text"
                        autoFocus={true}
                        className={styles.editInput}
                        value={editCell.value}
                        onChange={(e) => handleChange(e.target.value)}
                        onBlur={save}
                        onKeyUp={(e) => handleKeyUp(e)}
                      />
                    ) : (
                      row[cellKey]
                    )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </BootstrapTable>
    </div>
  )
}

export default Table