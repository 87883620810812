import React, { useState, useContext } from 'react'
import { Alert, Table, Modal, Button, Spinner } from 'react-bootstrap'
import ConfirmDeleteModal from '../ConfirmDeleteModal/ConfirmDeleteModal'
import EditAuthClientModal from '../EditAuthClientModal/EditAuthClientModal'
import './ViewAuthClientsModal.scss'
import { deleteAuthClient } from '../../api/api'
import { AuthClient } from '../../types/authClient'
import { AuthClientsContext } from '../../providers/AuthClientContext'

type ViewAuthClientsModalProps = {
  show: boolean
  handleClose: () => void
  title: string
  titleId: string
}

const ViewAuthClientsModal = ({ show, handleClose, title, titleId }: ViewAuthClientsModalProps) => {
  const { authClients, loading, error, updateAuthClient, removeAuthClient } = useContext(AuthClientsContext)
  const [showEditAuthClientModal, setShowEditAuthClientModal] = useState(false)
  const [showDeleteAuthClientModal, setShowDeleteAuthClientModal] = useState(false)
  const [authClientToDelete, setAuthClientToDelete] = useState<AuthClient | null>(null)
  const [authClientToEdit, setAuthClientToEdit] = useState<AuthClient | null>(null)

  const handleEditAuthClient = (authClient: AuthClient) => {
    setShowEditAuthClientModal(true)
    setAuthClientToEdit(authClient)
  }

  const handleDeleteAuthClient = (authClient: AuthClient) => {
    setAuthClientToDelete(authClient)
    setShowDeleteAuthClientModal(true)
  }

  const handleConfirmDelete = async () => {
    if (!authClientToDelete) return

    deleteAuthClient(authClientToDelete)
      .then(() => {
        removeAuthClient(authClientToDelete)
        setAuthClientToDelete(authClients.filter(client => client.id !== authClientToDelete.id).shift() ?? null)
        setShowDeleteAuthClientModal(false)
      })
  }

  const handleSuccessSave = (updatedClient: AuthClient) => {
    updateAuthClient(updatedClient)
    setShowEditAuthClientModal(false)
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Auth Clients for {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-scrollable">
          {loading ? (
            <Spinner animation="border" role="status" data-testid="loading-spinner">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <>
              {error && (
                <Alert variant="danger">
                                    There was an error fetching Auth Clients. Please try later.
                </Alert>
              )}
              {authClients.length === 0 && !error && (
                <Alert variant="info">No auth clients available. Configure an Auth Client to view it here.</Alert>
              )}
              {authClients.length > 0 && (
                <div className="table-responsive">
                  <Table striped borderless hover className="table-fixed-actions">
                    <thead>
                      <tr>
                        <th>Client ID</th>
                        <th>Client Name</th>
                        <th>Client Secret</th>
                        <th>Redirect URIs</th>
                        <th>Post logout redirect URIs</th>
                        <th className="action-column">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {authClients.map((authClient) => (
                        <tr key={authClient.id}>
                          <td>{authClient.clientId}</td>
                          <td>{authClient.clientName}</td>
                          <td>***</td>
                          <td>{authClient.redirectUris}</td>
                          <td>{authClient.postLogoutRedirectUris}</td>
                          <td className="action-column">
                            <div style={{ display: 'flex', gap: '1px' }}>
                              <Button variant="none" onClick={() => handleEditAuthClient(authClient)}>
                                <i className="bi bi-pencil-square"></i>
                              </Button>
                              <Button variant="none" onClick={() => handleDeleteAuthClient(authClient)}>
                                <i className="bi bi-trash3"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>

      <ConfirmDeleteModal
        show={showDeleteAuthClientModal}
        handleClose={() => setShowDeleteAuthClientModal(false)}
        handleConfirm={handleConfirmDelete}
      />
      <EditAuthClientModal
        show={showEditAuthClientModal}
        handleClose={() => setShowEditAuthClientModal(false)}
        titleId={titleId}
        authClient={authClientToEdit}
        onSuccessSave={handleSuccessSave}
      />
    </>
  )
}

export default ViewAuthClientsModal
