import axios, { AxiosRequestConfig } from 'axios'
import {
  AuthClientResponse, CommonsResponse,
  StartResponse,
} from './responses'
import { ImageCategory } from '../types/imageCategory'
import { AuthClient } from '../types/authClient'
import { TitleDetails } from '../types/titleDetails'
import { Country } from '../types/country'
import { logAndRethrowError } from '../utils/errorHandlers'

const API_BASE_URL = process.env.REACT_APP_API_URL

const baseApiConfig: AxiosRequestConfig = {
  headers: {
    'X-XSRF-TOKEN': sessionStorage.getItem('csrfToken'),
    contentType: 'application/json'
  },
  withCredentials: true
}

const formDataApiConfig = {
  headers: {
    'X-XSRF-TOKEN': sessionStorage.getItem('csrfToken'),
    contentType: 'multipart/form-data',
  },
  withCredentials: true
}

export const getCsrfToken = async (): Promise<StartResponse> => {
  return axios.get(`${API_BASE_URL}/api/v1/start`, baseApiConfig)
    .then(res => res.data)
    .catch(logAndRethrowError)
}

export const getTitles = async (): Promise<TitleDetails[]> => {
  return axios.get(`${API_BASE_URL}/v1/admin/titles`, baseApiConfig)
    .then((res) => res.data)
    .catch(logAndRethrowError)
}

export const getTitleDetailsByTitleId = async (titleId: string): Promise<TitleDetails | null> => {
  return axios.get(`${API_BASE_URL}/v1/admin/titles/${titleId}`, baseApiConfig)
    .then((res) => res.data)
    .catch(logAndRethrowError)
}

export const updateTitleDetails = async (titleId: string, updatedTitle: Partial<TitleDetails>): Promise<TitleDetails> => {
  return axios.put(`${API_BASE_URL}/v1/admin/titles/${titleId}`, updatedTitle, baseApiConfig)
    .then((res) => res.data)
    .catch(logAndRethrowError)
}

export const uploadImage = async (image: File, imageCategory: ImageCategory, titleId: string): Promise<CommonsResponse> => {
  const formData = new FormData()
  formData.append('file', image)
  return axios.post(`${API_BASE_URL}/v1/admin/titles/${imageCategory}/${titleId}`, formData, formDataApiConfig)
    .then((res) => res.data)
    .catch(logAndRethrowError)
}

export const deleteImage = async (titleId: string, imageCategory: ImageCategory, imageUrl: string): Promise<CommonsResponse> => {
  return axios.delete(`${API_BASE_URL}/v1/admin/titles/${imageCategory}/${titleId}?imageUrl=${imageUrl}`)
    .then((res) => res.data)
    .catch(logAndRethrowError)
}

export const getAuthClients = async (): Promise<AuthClient[]> => {
  return axios.get(`${API_BASE_URL}/v1/admin/authclients`, baseApiConfig)
    .then((res) => res.data)
    .catch(logAndRethrowError)
}

export const getAuthClientByTitleId = async (titleId: string): Promise<AuthClientResponse> => {
  return axios.get(`${API_BASE_URL}/v1/admin/authclients/${titleId}`, baseApiConfig)
    .then((res) => res.data)
    .catch(logAndRethrowError)
}

export const createAuthClient = async (authClient: Partial<AuthClient>): Promise<AuthClient> => {
  return axios.post(`${API_BASE_URL}/v1/admin/authclients`, authClient, baseApiConfig)
    .then((res) => res.data)
    .catch(logAndRethrowError)
}

export const updateAuthClient = async (authClient: AuthClient): Promise<AuthClient> => {
  return axios.put(`${API_BASE_URL}/v1/admin/authclients/${authClient.id.split('_')[1]}`, authClient, baseApiConfig)
    .then((res) => res.data)
    .catch(logAndRethrowError)
}

export const deleteAuthClient = async (authClient: AuthClient): Promise<CommonsResponse> => {
  return axios.delete(`${API_BASE_URL}/v1/admin/authclients/${authClient.id.split('_')[1]}/${authClient.sk}`, baseApiConfig)
    .then((res) => res.data)
    .catch(logAndRethrowError)
}

export const getCountries = async (): Promise<Country[]> => {
  return axios.get(`${API_BASE_URL}/v1/admin/countries`, baseApiConfig)
    .then((res) => res.data)
    .catch(logAndRethrowError)
}