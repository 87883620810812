import React, { useState, useEffect } from 'react'
import { Alert, Form, Modal, Button } from 'react-bootstrap'
import { updateAuthClient } from '../../api/api'
import { AuthClient } from '../../types/authClient'

type EditAuthClientModalProps = {
  show: boolean
  handleClose: () => void
  titleId: string
  authClient: AuthClient
  onSuccessSave: (authClient: AuthClient) => void
}

const EditAuthClientModal = ({
  show, handleClose, titleId, authClient, onSuccessSave
}: EditAuthClientModalProps) => {
  // @todo replace secret, name and uris with activeAuthClient
  const [clientSecret, setClientSecret] = useState('')
  const [clientName, setClientName] = useState('')
  const [redirectUris, setRedirectUris] = useState('')
  const [postLogoutRedirectUris, setPostLogoutRedirectUris] = useState('')
  const [saving, setSaving] = useState(false)
  const [saveError, setSaveError] = useState(null)

  useEffect(() => {
    if (show && authClient) {
      setClientSecret(authClient.clientSecret)
      setClientName(authClient.clientName)
      setRedirectUris(authClient.redirectUris)
      setPostLogoutRedirectUris(authClient.postLogoutRedirectUris)
    }
  }, [show, authClient, titleId])

  const handleSave = async() => {
    setSaving(true)
    setSaveError(null)

    const authClientData = {
      ...authClient,
      clientSecret: clientSecret,
      clientName: clientName,
      redirectUris: redirectUris,
      postLogoutRedirectUris: postLogoutRedirectUris,
    }

    updateAuthClient(authClientData)
      .then((updatedAuthClient: AuthClient) => {
        handleClose()
        onSuccessSave(updatedAuthClient)
      })
      .catch((e) => setSaveError(e))
      .finally(() => setSaving(false))
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit AuthClient</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className='auth-form'>
          <Form.Group controlId="clientName">
            <Form.Label>Client Name</Form.Label>
            <Form.Control
              type="text"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
              placeholder="Enter client name"
              required
            />
          </Form.Group>
          <Form.Group controlId="clientSecret">
            <Form.Label>Client Secret</Form.Label>
            <Form.Control
              type="text"
              value={clientSecret}
              onChange={(e) => setClientSecret(e.target.value)}
              placeholder="Enter client secret"
              required
            />
          </Form.Group>
          <Form.Group controlId="redirectUris">
            <Form.Label>Redirect URIs</Form.Label>
            <Form.Control
              type="text"
              value={redirectUris}
              onChange={(e) => setRedirectUris(e.target.value)}
              placeholder="Enter redirect URIs, comma separated"
              required
            />
          </Form.Group>
          <Form.Group controlId="postLogoutRedirectUris">
            <Form.Label>Post Logout Redirect URIs</Form.Label>
            <Form.Control
              type="text"
              value={postLogoutRedirectUris}
              onChange={(e) => setPostLogoutRedirectUris(e.target.value)}
              placeholder="Enter post logout redirect URIs, comma separated"
              required
            />
          </Form.Group>
        </Form>
        {saveError && <Alert variant="danger" className="mt-3">{saveError}</Alert>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
                    Close
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={saving}>
          {saving ? 'Saving...' : 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditAuthClientModal
