import React, { useState, useEffect } from 'react'
import { Modal, Button, Spinner, Alert, Form, FormControl, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import './SelectCountriesModal.scss'
import { getCountries, getTitleDetailsByTitleId, updateTitleDetails } from '../../api/api'

type SelectCountriesModalProps = {
  show: boolean
  handleClose: () => void
  title: string
  titleId: string
}

function SelectCountriesModal({ show, handleClose, title, titleId }: SelectCountriesModalProps) {
  const [countries, setCountries] = useState([])
  const [selectedCountries, setSelectedCountries] = useState([])
  const [loading, setLoading] = useState(true)
  const [fetchCountriesError, setFetchCountriesError] = useState(null)
  const [saving, setSaving] = useState(false)
  const [saveError, setSaveError] = useState(null)
  const [search, setSearch] = useState('')

  useEffect(() => {
    const getCountriesPromise = getCountries()
      .then((countries) => setCountries(countries))
      .catch(e => setFetchCountriesError(e))

    const getTitleDetailsPromise = getTitleDetailsByTitleId(titleId)
      .then((titleDetails) => setSelectedCountries(titleDetails.unavailableCountries))
      .catch(e => setFetchCountriesError(e))

    if (show) {
      Promise
        .all([getCountriesPromise, getTitleDetailsPromise])
        .finally(() => setLoading(false))
    }
  }, [show, titleId])

  const handleCheckboxChange = (countryCode: string) => {
    setSelectedCountries((prevSelected) => {
      const currentSelected = prevSelected || []
      console.log('currentSelected', currentSelected)
      console.log('prevSelected', prevSelected)
      console.log('countries', countries)

      return currentSelected.includes(countryCode)
        ? currentSelected.filter((code) => code !== countryCode)
        : [...currentSelected, countryCode]
    })
  }

  const handleSaveUnavailableCountries = async () => {
    setSaving(true)
    setSaveError(null)

    updateTitleDetails(titleId, { unavailableCountries: selectedCountries })
      .then(handleClose)
      .catch((e) => setSaveError(e))
      .finally(() => setSaving(false))
  }

  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase()?.includes(search.toLowerCase())
  )

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='custom-modal-body'>
        {loading ? (
          <Spinner animation="border" role="status" data-testid="loading-spinner">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <>
            {fetchCountriesError ? (
              <Alert variant="danger">
                                Unable to load the list of countries. Please try again later.
              </Alert>
            ) : (
              <>
                <InputGroup>
                  <InputGroup.Text id="search">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                  <FormControl
                    type="text"
                    aria-label='search-text'
                    placeholder="Search countries"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </InputGroup>

                <div className="modal-form-control mt-3">
                  <div className="modal-form-control-inner">
                    <Form>
                      {filteredCountries.map((country) => (
                        <Form.Check
                          key={country.country_id}
                          type="checkbox"
                          className='mb-3'
                          id={`country-${country.country_id}`}
                          checked={selectedCountries?.includes(country.country_code)}
                          onChange={() => handleCheckboxChange(country.country_code)}
                          label={
                            <div>
                              <span>{country.name}</span>
                              <span className="country-code">({country.country_code})</span>
                            </div>
                          }
                        />
                      ))}
                    </Form>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {saveError && <Alert variant="danger" className="mt-3">{saveError}</Alert>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={saving}>
                    Close
        </Button>
        <Button variant="primary" onClick={handleSaveUnavailableCountries} disabled={saving}>
          {saving ? 'Saving...' : 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SelectCountriesModal
