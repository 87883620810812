import { AuthClient } from '../types/authClient'
import { TitleDetails } from '../types/titleDetails'
import { Country } from '../types/country'

export const getMockAuthClient = (): AuthClient => ({
  PK: 'AUTHCLIENT#00c0d053-7085-4053-8564-c61668f13dc8',
  SK: (Math.floor(Math.random() * 100) + 1).toString(),
  clientId: (Math.floor(Math.random() * 100) + 1).toString(),
  clientName: 'tradeTycoon',
  clientSecret: '$2a$10$MrHtsf6zcLJ/29EEUvzKuuvyExWaFyQy.7Jg2yoLxA7/K0/WQdCHC',
  id: `${(Math.floor(Math.random() * 100) + 1).toString()}_123`,
  pk: 'AUTHCLIENT#00c0d053-7085-4053-8564-c61668f13dc8',
  postLogoutRedirectUris: '123',
  redirectUris: '1233',
  sk: (Math.floor(Math.random() * 100) + 1).toString(),
  title: 'Trade Tycoon',
  titleId: (Math.floor(Math.random() * 100) + 1).toString()
})

export const getTitleMock = (): TitleDetails => ({
  activePortfolioEndDate: null,
  activePortfolioStartDate: null,
  bannerImages: null,
  description: null,
  featuredGamesImages: null,
  gameCardImages: null,
  gameLaunchUrl: '',
  pk: 'TITLE#193',
  platforms: [],
  punchline: null,
  recommendedGamesImages: null,
  shortTitle: 'tradetycoon',
  sk: 'TITLE#193',
  studio: 'Imperia Online',
  tags: ['FEATURED'],
  title: 'Trade Tycoon',
  titleId: (Math.floor(Math.random() * 100) + 1).toString(),
  type: 'TITLE',
  unavailableCountries: []
})

export const getCountryMock = (): Country => ({
  country_code: 'MN',
  country_id: (Math.floor(Math.random() * 100) + 1).toString(),
  name: 'Mongolia',
  pk: 'COUNTRY#407',
  sk: 'COUNTRY#407',
  type: 'COUNTRY'
})