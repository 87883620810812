import React from 'react'
import { Modal, Button } from 'react-bootstrap'

type ConfirmDeleteModalProps = {
    show: boolean
    handleClose: () => void
    handleConfirm: () => void
}

const ConfirmDeleteModal = ({
  show, handleClose, handleConfirm
}: ConfirmDeleteModalProps) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
                Are you sure you want to delete the auth client?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
                    Cancel
        </Button>
        <Button variant="danger" onClick={handleConfirm}>
                    Yes, Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmDeleteModal
