import React, { createContext, ReactNode, useEffect, useState } from 'react'
import { TitleDetails } from '../types/titleDetails'
import { getTitles } from '../api/api'

type TitleDetailsContextProps = {
  titleDetails: TitleDetails[]
  loading: boolean
  error: string | null
}

export const TitleDetailsContext = createContext<TitleDetailsContextProps>({
  titleDetails: [],
  loading: true,
  error: null
})

export const TitleDetailsProvider = ({ children }: {children: ReactNode}) => {
  const [titleDetails, setTitleDetails] = useState<TitleDetails[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    getTitles()
      .then((titleDetails) => setTitleDetails(titleDetails))
      .catch((error) => setError(error.toString()))
      .finally(() => setLoading(false))
  }, [])

  return(
    <TitleDetailsContext.Provider value={{ titleDetails, loading, error }}>
      {children}
    </TitleDetailsContext.Provider>
  )
}